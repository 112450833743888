import axios from "axios";
import { TOKEN_NAME } from "../contexts/AuthContext";
import arrayBufferToBase64 from "../utils/arrayBufferToBase64";

const BASE_URL = process.env.REACT_APP_PWS_URL;

const pwsInstance = axios.create();

pwsInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem(`${TOKEN_NAME}-partners`);

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

export function getCompanies(query = "", queryParams = {}) {
  return pwsInstance.get(`${BASE_URL}/empresa?query=${query}`, {
    params: queryParams,
  });
}

export function getCompany(companyCode, subsidiaryCode) {
  return pwsInstance.get(
    `${BASE_URL}/empresa/${companyCode}/filial/${subsidiaryCode}`,
  );
}

export function getCostCenters(
  companyCode,
  subsidiaryCode,
  query = "",
  queryParams = {},
) {
  return pwsInstance.get(
    `${BASE_URL}/empresa/${companyCode}/filial/${subsidiaryCode}/centro_custo?query=${query}`,
    {
      params: queryParams,
    },
  );
}

export function getEmployees(
  companyCode,
  subsidiaryCode,
  query = "",
  queryParams = {},
) {
  return pwsInstance.get(
    `${BASE_URL}/empresa/${companyCode}/filial/${subsidiaryCode}/funcionario?query=${query}`,
    {
      params: queryParams,
    },
  );
}

export function getHolidays({ queryParams = {} }) {
  return pwsInstance.get(`${BASE_URL}/feriado`, {
    params: queryParams,
  });
}

export function getOrgchart(companyCode, subsidiaryCode) {
  return pwsInstance.get(
    `${BASE_URL}/empresa/${companyCode}/filial/${subsidiaryCode}/funcionario/organograma`,
  );
}

export function getManagers(
  companyCode,
  subsidiaryCode,
  query = "",
  queryParams = {},
) {
  return pwsInstance.get(
    `${BASE_URL}/empresa/${companyCode}/filial/${subsidiaryCode}/gestor?query=${query}`,
    {
      params: queryParams,
    },
  );
}

export function getManager(companyCode, subsidiaryCode, matricula) {
  return pwsInstance.get(
    `${BASE_URL}/empresa/${companyCode}/filial/${subsidiaryCode}/gestor/matricula/${matricula}`,
  );
}

export function storeManager({
  companyCode,
  subsidiaryCode,
  managerEnrollNumber,
}) {
  return pwsInstance.post(
    `${BASE_URL}/empresa/${companyCode}/filial/${subsidiaryCode}/gestor`,
    {
      matricula: managerEnrollNumber,
    },
  );
}

export function inactivateManager({
  companyCode,
  subsidiaryCode,
  managerEnrollNumber,
}) {
  return pwsInstance.delete(
    `${BASE_URL}/empresa/${companyCode}/filial/${subsidiaryCode}/gestor/matricula/${managerEnrollNumber}`,
  );
}

export async function getEnrollForm(companyCode, subsidiaryCode, enrollNumber) {
  const response = await pwsInstance.get(
    `${BASE_URL}/empresa/${companyCode}/filial/${subsidiaryCode}/ficha_cadastro/matricula/${enrollNumber}?mimeType=application/pdf`,
    { responseType: "arraybuffer" },
  );

  return arrayBufferToBase64(response.data);
}

export function getContracts(companyCode, subsidiaryCode) {
  return pwsInstance.get(
    `${BASE_URL}/empresa/${companyCode}/filial/${subsidiaryCode}/kit_admissao`,
  );
}

export async function getAdmissionKit(
  companyCode,
  subsidiaryCode,
  contractId,
  enrollNumber,
) {
  const response = await pwsInstance.get(
    `${BASE_URL}/empresa/${companyCode}/filial/${subsidiaryCode}/kit_admissao/matricula/${enrollNumber}?mimeType=application/pdf&base64=true&contratoId=${contractId}`,
  );

  return response.data;
}

export async function getESocialReceipt(
  companyCode,
  subsidiaryCode,
  enrollNumber,
  minDate,
) {
  const response = await pwsInstance.get(
    `${BASE_URL}/empresa/${companyCode}/filial/${subsidiaryCode}/recibo_esocial/matricula/${enrollNumber}/tabela/1/dt_min/${minDate}?mimeType=application/pdf`,
    { responseType: "arraybuffer" },
  );

  return arrayBufferToBase64(response.data);
}

export function getTableHealth(companyCode, subsidiaryCode, queryParams = {}) {
  return pwsInstance.get(
    `${BASE_URL}/empresa/${companyCode}/filial/${subsidiaryCode}/saude`,
    {
      params: queryParams,
    },
  );
}

export function generateProcedureReport({
  companies,
  date,
  procedure,
  consolidate,
  reportTitle,
}) {
  return pwsInstance.post(`${BASE_URL}/relatorio`, {
    empresas: companies,
    data: date,
    procedure: procedure,
    consolidado: consolidate,
    nomeRelatorio: reportTitle,
  });
}

export function getBatchEnrollForm(
  companyCode,
  subsidiaryCode,
  queryParams = {},
) {
  return pwsInstance.get(
    `${BASE_URL}/empresa/${companyCode}/filial/${subsidiaryCode}/ficha_cadastro/lote`,
    {
      params: queryParams,
    },
  );
}

export function generatePayrollReport(
  companyCode,
  subsidiaryCode,
  monthRef,
  queryParams = {},
) {
  return pwsInstance.get(
    `${BASE_URL}/empresa/${companyCode}/filial/${subsidiaryCode}/relatorio_movimento/${monthRef}`,
    {
      params: queryParams,
    },
  );
}

export function getPayrollHistory(
  companyCode,
  subsidiaryCode,
  enrollNumber,
  queryParams = {},
) {
  return pwsInstance.get(
    `${BASE_URL}/empresa/${companyCode}/filial/${subsidiaryCode}/historico_movimento/matricula/${enrollNumber}`,
    {
      params: queryParams,
    },
  );
}

export function getPayrollUrl(
  companyCode,
  subsidiaryCode,
  enrollNumber,
  reference,
  type,
  queryParams = {},
) {
  return pwsInstance.get(
    `${BASE_URL}/empresa/${companyCode}/filial/${subsidiaryCode}/holerite/matricula/${enrollNumber}/data_referencia/${reference}/tipo/${type}`,
    {
      params: queryParams,
    },
  );
}
